.nd-spinner-inline {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    pointer-events: none;
    z-index: 1;
}
